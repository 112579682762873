import React, { useState } from "react";
import { Customer } from "../domain/customerType";
import { RegisterType } from "../domain/registerType";
import http from "../lib/http";

const url = "https://ebc0b1405494.ngrok.io/customer";
// const url = "http://172.16.32.121:8080/customer";

const getCustomer = () => {
  return http.get(url);
};

const getCustId = (custId: string) => {
  return http.get(`${url}/getById/${custId}`);
};

const create = (registerType: RegisterType) => {
  return http.post(url, registerType);
};

const getCustLineId = (lineId: string) => {
  return http.get(`${url}/getLineId/${lineId}`);
};

export default {
  getCustomer,
  getCustId,
  create,
  getCustLineId,
};
