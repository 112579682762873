import React, { useState, useEffect } from "react";
import { Card, Col, Form, Input, Row, Button } from "antd";
import TabShipping from "./TabShipping";
import TabShipped from "./TabShipped";

const { TextArea } = Input;

const tabStatus = [
  {
    key: "shipping",
    tab: "อยู่ระหว่างจัดส่ง",
  },
  {
    key: "shipped",
    tab: "จัดส่งสำเร็จ",
  },
];

// const detailStatus = {
//   shipping: <p>อยู่ระหว่างจัดส่ง</p>,
//   shipped: <p>จัดส่งสำเร็จ</p>,
// };

const StatusOrder: React.FC = () => {
  const [form] = Form.useForm();
  const [keyTab, setKeyTab] = useState<string>("shipping");

  const state = {
    key: "shipping",
  };

  const onTabChange = (key: any, type: any) => {
    console.log(key, type);
    // this.setState({ [type]: key });
    setKeyTab(key);
  };

  return (
    <>
      <Row>
        <Col span={1}></Col>
        <Col span={22}>
          <Form form={form} name="basic">
            <br />
            <Card
              style={{ width: "100%" }}
              tabList={tabStatus}
              activeTabKey={keyTab}
              // tabBarExtraContent={<a href="#">More</a>}
              onTabChange={(key) => {
                onTabChange(key, "key");
              }}
            >
              {keyTab === "shipping" && (
                <div>
                  <TabShipping />
                </div>
              )}
              {keyTab === "shipped" && (
                <div>
                  <TabShipped />
                </div>
              )}
            </Card>
          </Form>
        </Col>
        <Col span={1}></Col>
      </Row>
    </>
  );
};

export default StatusOrder;
