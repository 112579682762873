import React, { useState } from "react";
import { useEffect } from "react";

const ViewOrderUser = () => {
  const [user, setUser] = useState<string>("");

  useEffect(() => {
    const userString = localStorage.getItem("user") as string;
    setUser(userString);
  }, []);

  return <div>user : {user}</div>;
};

export default ViewOrderUser;
