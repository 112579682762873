import React from "react";
import {
  Image,
  Col,
  Form,
  Input,
  Row,
  Typography,
  Button,
  DatePicker,
  Divider,
} from "antd";
import { format } from "path";
import GoogleMapReact from "google-map-react";

import mapIcon from "../../images/map_icon.png";
import checkInIcon from "../../images/checkIn_icon.png";
import { useHistory } from "react-router-dom";

const { Title, Paragraph, Text, Link } = Typography;
const { TextArea } = Input;

const AnyReactComponent = ({
  lat,
  lng,
  text,
}: {
  lat: number;
  lng: number;
  text: string;
}) => <Image preview={false} width={25} src={checkInIcon} />;

const SelectOrder: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const date = Date.now();
  const monthNums = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const dateObj = new Date();
  const month = monthNums[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear() + 543;
  const dateStr = " : " + day + "/" + month + "/" + year;

  const defaultProps = {
    center: {
      lat: 13.03887,
      lng: 101.490104,
    },
    zoom: 8,
  };

  return (
    <>
      <Row>
        <Col span={1}></Col>
        <Col span={22}>
          <Form form={form} name="basic">
            <br />
            <div
              style={{
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              วันที่
              {dateStr}
            </div>
            <hr
              style={{ marginTop: -1, height: 4, backgroundColor: " #A3D6D4" }}
            />

            <Row style={{ marginTop: "2em" }}>
              <TextArea
                // style={{
                //   border: "1.5px solid #D3D3D3",
                //   borderRadius: "7px",
                //   display: "inline-block",
                //   fontSize: "16px",
                //   padding: "4px",
                //   width: "100%",
                //   backgroundColor: "#f5f5f5",
                // }}
                style={{
                  border: "1.5px solid #D3D3D3",
                  borderRadius: "7px",
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                readOnly={true}
                value="ร้านณรงค์ดอกไม้สด 098 123 4569"
                autoSize={{ minRows: 2, maxRows: 5 }}
              />
            </Row>

            <hr
              style={{
                marginTop: "2em",
                height: 4,
                backgroundColor: " #A3D6D4",
              }}
            />
            <Row style={{ marginTop: "1em" }}>
              {/* <Form.Item label="เลือกสถานที่" style={{ marginBottom: 0 }}>
                <Form.Item>
                  <Image preview={false} width={25} src={mapIcon} />
                </Form.Item>
              </Form.Item> */}
              <div>
                <label
                  style={{
                    display: "inline-block",
                    margin: "5px 0",
                    fontWeight: "bold",
                  }}
                >
                  เลือกสถานที่
                </label>
                <Image
                  style={{ marginLeft: "0.8em" }}
                  preview={false}
                  width={25}
                  src={mapIcon}
                />
              </div>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  id="googleMap"
                  style={{ width: 400, height: 300, alignItems: "center" }}
                >
                  <GoogleMapReact
                    // AIzaSyBJsrNdBFU6d8XXw8RuG1WSUemMbAVj-ZQ
                    // AIzaSyDokz8yiJyqOX5SbfMZz3AQ1NHknT8VH3g
                    // bootstrapURLKeys={{
                    //   key: "AIzaSyBJsrNdBFU6d8XXw8RuG1WSUemMbAVj-ZQ",
                    // }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={defaultProps.center.lat}
                      lng={defaultProps.center.lng}
                      text="My Marker"
                    />
                  </GoogleMapReact>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "4em" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#4169E1",
                    color: "#fff",
                    borderRadius: "7px",
                    display: "block",
                    // margin: "10px 0 30px",
                    padding: "5px",
                    fontSize: "14px",
                    width: "100%",
                  }}
                  onClick={() => history.push("/app/flower/confirm")}
                >
                  เลือก
                </Button>
              </Col>
            </Row>
            <br />
          </Form>
        </Col>
        <Col span={1}></Col>
      </Row>
    </>
  );
};

export default SelectOrder;
