import React, { useState, useEffect } from "react";
import { Image, Col, Form, Input, Row, Typography, Button } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import orderService from "src/service/orderService";
import { Order } from "src/domain/orderType";
import { Customer } from "src/domain/customerType";
import customerService from "src/service/customerService";
import { OrderDelivery } from "src/domain/orderDeliveryType";
import { DeliveryOrder } from "src/domain/deliveryOrderType";

const { Title, Paragraph, Text, Link } = Typography;
const { TextArea } = Input;

interface IParams {
  custId: string;
}

const OrderForm: React.FC<RouteComponentProps<IParams>> = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [order, setOrder] = useState<Order>({} as Order);

  useEffect(() => {
    let custId: string = String(props.match.params.custId);
    customerService.getCustId(custId).then((res: any) => {
      console.log(res.data);
      setCustomer(res.data);
    });
  }, []);

  const monthNums = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const dateObj = new Date();
  const month = monthNums[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear() + 543;
  const dateStr = " : " + day + "/" + month + "/" + year;

  const confirmHandle = async (values: any) => {
    const order: Order = {
      id: 0,
      orderType: "DELIVERY",
      customerId: 4,
    };

    console.log("values >>>>: ", values);
    const deliveryOrder: DeliveryOrder = {
      consigneeName: values.consigneeName,
      consigneeTel: values.consigneeTel,
      remark: values.remark,
    };

    const orderDerivery: OrderDelivery = {
      order: order,
      deliveryOrder: deliveryOrder,
    };

    console.log("order delivery >>>:: ", orderDerivery);
    const res = await orderService.createOrder(orderDerivery);
    console.log("orderDerivery >>>>:: ", res.data);
    // history.push("/app/order/" + customer.id + "/view/" + res.data.orderNo);
  };

  return (
    <>
      <Row>
        <Col span={1}></Col>
        <Col span={22}>
          <Form form={form} name="basic" onFinish={confirmHandle}>
            <br />
            <div
              style={{
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              วันที่
              {dateStr}
            </div>
            <hr
              style={{ marginTop: -1, height: 4, backgroundColor: " #A3D6D4" }}
            />

            <Row style={{ marginTop: "2em" }}>
              <Col span={24}>
                <div
                  style={{
                    border: "1.5px solid #D3D3D3",
                    borderRadius: "8px",
                    backgroundColor: "#f5f5f5",
                    paddingLeft: "15px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      margin: "5px 0",
                      paddingRight: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {customer.name}
                  </label>
                  <br />
                  <label
                    style={{
                      display: "inline-block",
                      margin: "5px 0",
                      paddingRight: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {customer.tel}
                  </label>
                </div>
              </Col>
            </Row>

            <hr
              style={{
                marginTop: "2em",
                height: 4,
                backgroundColor: " #A3D6D4",
              }}
            />

            <Row style={{ marginTop: "2em" }}>
              {/* <Input name="userName" placeholder="ชื่อผู้รับ" /> */}
              <Col span={24}>
                <label
                  style={{
                    display: "inline-block",
                    margin: "5px 0",
                    fontWeight: "bold",
                  }}
                >
                  ชื่อผู้รับ
                </label>
                <Form.Item
                  style={{
                    width: "100%",
                  }}
                  name="consigneeName"
                >
                  <Input
                    // className={Styles.input}
                    style={{
                      border: "1.5px solid #D3D3D3",
                      borderRadius: "7px",
                      display: "inline-flex",
                      fontSize: "14px",
                      padding: "8px",
                    }}
                    placeholder="ชื่อผู้รับ"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label
                  style={{
                    display: "inline-block",
                    margin: "5px 0",
                    fontWeight: "bold",
                  }}
                >
                  เบอร์โทร
                </label>
                <Form.Item
                  style={{
                    width: "100%",
                  }}
                  name="consigneeTel"
                >
                  <Input
                    // className={Styles.input}
                    style={{
                      border: "1.5px solid #D3D3D3",
                      borderRadius: "7px",
                      display: "inline-flex",
                      fontSize: "14px",
                      padding: "8px",
                      width: "100%",
                    }}
                    placeholder="เบอร์โทร"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label
                  style={{
                    display: "inline-block",
                    margin: "5px 0",
                    fontWeight: "bold",
                  }}
                >
                  หมายเหตุ
                </label>
                <Form.Item
                  style={{
                    width: "100%",
                  }}
                  name="remark"
                >
                  <TextArea
                    rows={4}
                    style={{
                      border: "1.5px solid #D3D3D3",
                      borderRadius: "7px",
                      display: "inline-flex",
                      fontSize: "14px",
                      padding: "5px",
                      width: "100%",
                    }}
                    placeholder="หมายเหตุ"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: "2em" }}>
              <Col span={24}>
                <Button
                  style={{
                    backgroundColor: "#4169E1",
                    color: "#fff",
                    borderRadius: "7px",
                    display: "block",
                    // margin: "10px 0 30px",
                    padding: "5px",
                    fontSize: "14px",
                    width: "100%",
                  }}
                  // onClick={() => history.push("/app/order/view")}
                  // onClick={confirmHandle}
                  onClick={() => form.submit()}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={1}></Col>
      </Row>
    </>
  );
};

export default OrderForm;
