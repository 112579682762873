import { timingSafeEqual } from "crypto";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const CheckUser = () => {
  const [user, setUser] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    const userString = localStorage.getItem("user") as string;
    console.log("userString  >>>:: " + userString);
    if (userString === null) {
      history.push("/app/user/register");
    } else {
      history.push("/app/order/view/userorder");
    }
  }, []);

  return <div>user : {user}</div>;
};

export default CheckUser;
