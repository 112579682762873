import Reac, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import liff from "@line/liff";

const LIFF_ID = "1656263228-ExQyRAbr";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LineLogin = () => {
  const history = useHistory();

  const [action, setAction] = useState<string>("");
  const [orderType, setOrderType] = useState<string>("");
  const [orderItem, setOrderItem] = useState<string>("");

  let query = useQuery();

  useEffect(() => {
    showQuery();
    login();
  }, []);

  const showQuery = () => {
    const _action = query.get("action");
    const _orderType = query.get("orderType");
    const _orderItem = query.get("orderItem");

    setAction(_action!);
    setOrderType(_orderType!);
    setOrderItem(_orderItem!);

    localStorage.setItem("action", _action!);
    localStorage.setItem("orderType", _orderType!);
    localStorage.setItem("orderItem", _orderItem!);
  };

  const login = () => {
    liff
      .init({ liffId: LIFF_ID })
      .then((res) => {
        console.log("liff::", res);
        // liff.login({
        //   redirectUri: "https://9a412fea910a.ngrok.io/app/lineinfo",
        // });
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          history.push("/app/lineinfo");
        }
      })
      .catch((err) => {
        console.log("useEffect::liff::", err);
      });
  };

  return (
    <div>
      <div>Logging In ...</div>
      <div>
        <div>action : {action} </div>
        <div>orderType : {orderType} </div>
        <div>orderItem : {orderItem} </div>
      </div>
    </div>
  );
};

export default LineLogin;
