import Reac, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import liff from "@line/liff";
import { LineUserInfo } from "../../domain/lineUserInfoType";

const LIFF_ID = "1656263228-ExQyRAbr";

const LineInfo = () => {
  const history = useHistory();

  const [lineUserId, setLineUserId] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const [picURL, setPicURL] = useState<string>("");

  const [action, setAction] = useState<string>("");
  const [orderType, setOrderType] = useState<string>("");
  const [orderItem, setOrderItem] = useState<string>("");

  useEffect(() => {
    loadLineInfo();
    loadParam();
  }, []);

  useEffect(() => {
    if (lineUserId === "") return;

    const registeredUser = localStorage.getItem(lineUserId) as string;
    if (registeredUser === null || registeredUser === "") {
      history.push("/app/user/register");
    } else {
      history.push(`/app/order/${orderType}/${orderItem}`);
    }
  }, [lineUserId]);

  const loadLineInfo = () => {
    liff
      .init({ liffId: LIFF_ID })
      .then((res) => {
        console.log("liff::init", res);
        liff.getProfile().then((res) => {
          console.log("liff.getProfile", res);
          setLineUserId(res.userId);
          setDisplayName(res.displayName);
          setPicURL(res.pictureUrl!);

          const info: LineUserInfo = {
            lineUserId: res.userId,
            displayName: res.displayName,
            pictureURL: res.pictureUrl!,
          };

          localStorage.setItem("LINE_USER_INFO", JSON.stringify(info));
        });
      })
      .catch((err) => {
        console.log("useEffect::liff::", err);
      });
  };

  const loadParam = () => {
    const action: string = localStorage.getItem("action")!;
    const orderType = localStorage.getItem("orderType")!;
    const orderItem = localStorage.getItem("orderItem")!;

    setAction(action);
    setOrderType(orderType);
    setOrderItem(orderItem);
  };

  return (
    <div>
      <div>LINE UserID : {lineUserId}</div>
      <div>Display Name : {displayName}</div>
      <div>Picture URL : {picURL}</div>
      <hr />
      <div>Action : {action}</div>
      <div>orderType : {orderType}</div>
      <div>orderItem : {orderItem}</div>
    </div>
  );
};

export default LineInfo;
