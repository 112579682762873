import React, { useEffect, useState } from "react";
import {
  Space,
  Image,
  Grid,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
  Button,
  Card,
  Avatar,
  DatePicker,
  Cascader,
  AutoComplete,
} from "antd";
// import "antd/dist/antd.css";
import {
  UserOutlined,
  MobileOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

import GoogleMapReact from "google-map-react";

import mapIcon from "../../images/map_icon.png";
import checkInIcon from "../../images/checkIn_icon.png";
import { useHistory } from "react-router-dom";
import { Customer } from "src/domain/customerType";
import { RegisterType } from "src/domain/registerType";
import customerService from "src/service/customerService";
import options from "src/views/Register/address";
import GMap from "src/components/GMap";

const { TextArea } = Input;
const { Text } = Typography;

const AnyReactComponent = ({
  lat,
  lng,
  text,
}: {
  lat: number;
  lng: number;
  text: string;
}) => <Image preview={false} width={25} src={checkInIcon} />;

const Register: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [customer, setCustomer] = useState<Customer>({} as Customer);

  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);

  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 13.03887,
    lng: 101.490104,
  });

  // const defaultProps = {
  //   center: {
  //     lat: 13.03887,
  //     lng: 101.490104,
  //   },
  //   zoom: 8,
  // };

  useEffect(() => {
    console.log(" useEffect >>>>:: ");
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log(" Location >>>>:: ");
      console.log("Lat is :", position.coords.latitude);
      console.log("Lng is :", position.coords.longitude);
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  const onLocationHandler = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log(" Location >>>>:: ");
      console.log("Lat is :", position.coords.latitude);
      console.log("Lng is :", position.coords.longitude);
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  const onFinish = async (values: any) => {
    // console.log("values >>>:", values);
    const _customer: Customer = {
      name: values.username,
      tel: values.tel,
      address: values.address,
      locationCode: values.addressPath[2], //tambon
      addrPath:
        values.addressPath[0] +
        "/" +
        values.addressPath[1] +
        "/" +
        values.addressPath[2],
      lat: lat.toString(),
      lng: lng.toString(),
    };
    console.log("_customer >>:: ", _customer);

    const _registerType: RegisterType = {
      customer: _customer,
      userLineId: "U1212122121232",
    };

    const res = await customerService.create(_registerType);
    console.log("response ::", res);
  };

  function onChange(value: any, selectedOptions: any) {
    console.log(
      "onChange >>>>>:: ",
      value,
      "selectedOptions >>>:: ",
      selectedOptions
    );
  }

  function filter(inputValue: any, path: any) {
    return path.some(
      (option: any) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

  return (
    <>
      <Row>
        <Col span={1} />
        <Col span={22}>
          <Form form={form} name="basic" onFinish={onFinish}>
            <br />
            <Row>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  size={100}
                  shape="circle"
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                />
              </Col>
            </Row>
            <br />

            <Row>
              <label
                style={{
                  display: "inline-block",
                  margin: "5px 0",
                  fontWeight: "bold",
                }}
              >
                ชื่อ-สกุล
              </label>
            </Row>
            <Row>
              <Form.Item
                name="username"
                style={{
                  width: "100%",
                }}
                rules={[{ required: true, message: "กรุณาระบุ ชื่อ-สกุล" }]}
              >
                <Input
                  // className={Styles.input}
                  style={{
                    border: "1.5px solid #D3D3D3",
                    borderRadius: "7px",
                    display: "inline-flex",
                    fontSize: "14px",
                    padding: "8px",
                    width: "100%",
                  }}
                  placeholder="ชื่อ-สกุล"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
            </Row>

            <Row>
              <label
                style={{
                  display: "inline-block",
                  margin: "5px 0",
                  fontWeight: "bold",
                }}
              >
                โทรศัพท์
              </label>
            </Row>
            <Row>
              <Form.Item
                name="tel"
                style={{
                  width: "100%",
                }}
                rules={[{ required: true, message: "กรุณาระบุ โทรศัพท์" }]}
              >
                <Input
                  style={{
                    border: "1.5px solid #D3D3D3",
                    borderRadius: "7px",
                    display: "inline-flex",
                    fontSize: "14px",
                    padding: "8px",
                    width: "100%",
                  }}
                  placeholder="โทรศัพท์"
                  prefix={<MobileOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
            </Row>

            <hr
              style={{
                marginTop: "2em",
                height: 4,
                backgroundColor: " #A3D6D4",
              }}
            />

            <Row style={{ marginTop: "2em" }}>
              {/* <Input name="userName" placeholder="ชื่อผู้รับ" /> */}
              <Col span={24}>
                <label
                  style={{
                    display: "inline-block",
                    margin: "5px 0",
                    fontWeight: "bold",
                  }}
                >
                  ที่อยู่
                </label>

                <Form.Item
                  name="address"
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุ บ้านเลขที่/หมู่บ้าน/ถนน/ซอย",
                    },
                  ]}
                >
                  <Input
                    style={{
                      border: "1.5px solid #D3D3D3",
                      borderRadius: "7px",
                      display: "inline-flex",
                      fontSize: "14px",
                      padding: "8px",
                      width: "100%",
                    }}
                    placeholder="บ้านเลขที่/หมู่บ้าน/ถนน/ซอย"
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    width: "100%",
                  }}
                  name="addressPath"
                  rules={[
                    { required: true, message: "กรุณาระบุ จังหวัด/อำเภอ/ตำบล" },
                  ]}
                >
                  <Cascader
                    options={options}
                    onChange={onChange}
                    placeholder="จังหวัด/อำเภอ/ตำบล"
                    showSearch={{ filter }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: "1em" }}>
              <Col span={24}>
                <div>
                  <label
                    style={{
                      display: "inline-block",
                      margin: "5px 0",
                      fontWeight: "bold",
                    }}
                  >
                    เลือกสถานที่
                  </label>
                  <Image
                    style={{ marginLeft: "0.8em" }}
                    preview={false}
                    width={25}
                    src={mapIcon}
                  />
                </div>
                <div>
                  <span>
                    lat: {lat}, lng: {lng}
                  </span>
                  <Button onClick={onLocationHandler}>Get LatLng</Button>
                </div>
                {<GMap center={center} />}
              </Col>
            </Row>

            <Row>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Col>
            </Row>

            <Row style={{ marginTop: "2em" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#4169E1",
                    color: "#fff",
                    borderRadius: "7px",
                    display: "block",
                    // margin: "10px 0 30px",
                    padding: "5px",
                    fontSize: "14px",
                    width: "100%",
                  }}
                  // onClick={() => history.push("/app/flower/select")}
                  onClick={() => form.submit()}
                >
                  บันทึก
                </Button>
                <Button
                  style={{
                    backgroundColor: "#696969",
                    color: "#fff",
                    borderRadius: "7px",
                    display: "block",
                    // margin: "10px 0 30px",
                    padding: "5px",
                    fontSize: "14px",
                    width: "100%",
                    marginLeft: "0.2em",
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
            </Row>
            <br />
          </Form>
        </Col>
        <Col span={1} />
      </Row>
    </>
  );
};

export default Register;
