import { useState } from "react";
import { Order } from "src/domain/orderType";
import { DeliveryOrder } from "src/domain/deliveryOrderType";
import http from "../lib/http";
import { OrderDelivery } from "src/domain/orderDeliveryType";

const url = "http://192.168.1.34:8080/order";
// const url = "http://172.16.32.121:8080/order";

const getOrder = () => {
  return http.get(url);
};

const createOrder = (orderDerivery: OrderDelivery) => {
  return http.post(url, orderDerivery);
};

const getOrderNo = (orderNo: string) => {
  return http.get(`${url}/getByOrderNo/${orderNo}`);
};

export default {
  getOrder,
  createOrder,
  getOrderNo,
};
