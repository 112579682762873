import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";

interface IParams {
  item: string;
}

const NewOrderItem: React.FC<RouteComponentProps<IParams>> = (props) => {
  console.log("NewOrderItem Prop", props);

  const [user, setUser] = useState<string>("");
  const onClickHandler = (e: any) => {
    console.log("user", user);
    localStorage.setItem("user", user);
  };

  return (
    <>
      <div>order item : {props.match.params.item} </div>
      <div>
        <label>user : </label>
        <input
          type="text"
          onChange={(e) => setUser(e.target.value)}
          value={user}
        ></input>
        <button onClick={onClickHandler}>Set</button>
      </div>
    </>
  );
};

export default NewOrderItem;
