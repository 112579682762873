import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import Dashboard from "./Dashboard";
import Regex from "./Regex";
import Register from "./Register/Register";
import SelectOrder from "./Order/SelectOrder";
import OrderForm from "./Order/OrderForm";
import ViewOrder from "./Order/ViewOrder";
import StatusOrder from "./Order/StatusOrder";
import NewOrderItem from "./Order/NewOrderItem";
import ViewOrderUser from "./Order/ViewOrderUser";
import TabShipping from "./Order/TabShipping";
import CheckUser from "./CheckUser";
import OrderRequest from "./Order/OrderRequest";
import LineLogin from "./Line/LineLogin";
import LineInfo from "./Line/LineInfo";

const AppRoute: React.FC<RouteComponentProps> = (props) => {
  // console.log(props);
  return (
    <AppLayout>
      <Switch>
        {/* line login */}
        <Route exact path="/app/loginline" component={LineLogin} />
        <Route exact path="/app/lineinfo" component={LineInfo} />

        {/* dashboard */}
        <Route exact path="/app/dashboard" component={Dashboard} />

        <Route exact path="/app/user/check" component={CheckUser} />
        <Route exact path="/app/user/register" component={Register} />

        {/* order */}
        <Route
          exact
          path="/app/order/:orderType/:orderItem"
          component={OrderRequest}
        />
        <Route exact path="/app/order/:item" component={NewOrderItem} />
        <Route
          exact
          path="/app/order/view/userorder"
          component={ViewOrderUser}
        />
        <Route exact path="/app/order/:custId/register" component={Register} />
        <Route exact path="/app/order/:custId/select" component={SelectOrder} />
        <Route
          exact
          path="/app/order/cust/:custId/form"
          component={OrderForm}
        />
        <Route
          exact
          path="/app/order/:custId/view/:orderNo"
          component={ViewOrder}
        />
        <Route exact path="/app/order/status" component={StatusOrder} />
        {/* <Route exact path="/app/order/tabShipping" component={TabShipping} /> */}
        {/* <Redirect path="/app/order" to="/app/order/status" /> */}
        {/* <Redirect path="/app/order/form" to="/app/order/orderForm" /> */}

        {/* regex */}
        <Route exact path="/app/regex" component={Regex} />

        {/* other */}
        <Redirect path="/app" to="/app/order" />
      </Switch>
    </AppLayout>
  );
};

export default AppRoute;
