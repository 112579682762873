import React, { Children, PropsWithChildren, useEffect, useState } from "react";
import liff from "@line/liff";
import { useHistory } from "react-router-dom";

const LIFF_ID = "1656263228-ExQyRAbr";

// interface IProps {
//   children: React.ReactNode;
// }

type ComponentProps = PropsWithChildren<{}>;

const LineGuard: React.FC<ComponentProps> = (props) => {
  console.log("entry line guard");

  useEffect(() => {
    const userLineId = localStorage.getItem("LINE_USER_ID") as string;
    console.log("useEffect::localStorage::(userLineId)", userLineId);
    if (userLineId === null) {
      loadLiff();
    }
    // loadLiff();

    console.log("end effect");
  }, []);

  const setLineToStorage = (
    lineUserId: string,
    displayName: string,
    pictureUrl: string
  ) => {
    localStorage.setItem("LINE_USER_ID", lineUserId);
    localStorage.setItem("DISPLAY_NAME", displayName);
    localStorage.setItem("PICTURE_URL", pictureUrl);
  };

  const loadLiff = () => {
    liff
      .init({ liffId: `1656263228-ExQyRAbr` })
      .then(() => {
        console.log("this is init 1");
        if (liff.isLoggedIn()) {
          console.log("line is loggedIn");
          liff.getProfile().then((res) => {
            console.log("res line", res);
            // setLineToStorage(
            //   res.userId,
            //   res.displayName,
            //   res.pictureUrl as string
            // );
          });
        } else {
          console.log("line is not login in");
          liff.login();

          liff
            .init({ liffId: LIFF_ID })
            .then(() => {
              console.log("this is init 2");
              liff.getProfile().then((res) => {
                console.log("res", res);
                setLineToStorage(
                  res.userId,
                  res.displayName,
                  res.pictureUrl as string
                );
              });
            })
            .catch((err) => {
              console.log(
                "error xxx",
                err.response ? err.response.data : err.response
              );
              throw err;
            });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <div>
      Check Line
      {props.children}
    </div>
  );
};
export default LineGuard;
