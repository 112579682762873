import React from "react";
import { Image } from "antd";
import GoogleMapReact from "google-map-react";
import checkInIcon from "src/images/checkIn_icon.png";

interface IProps {
  center: { lat: number; lng: number };
}

const AnyReactComponent = ({
  lat,
  lng,
  text,
}: {
  lat: number;
  lng: number;
  text: string;
}) => <Image preview={false} width={25} src={checkInIcon} />;

const GMap: React.FC<IProps> = (props) => {
  return (
    <div
      id="googleMap"
      // style="display:flex;width:100%;flex:auto;"
      // style="width: auto; height: 25px; position: relative; float: right; display:inline-block;"
      style={{
        width: 450,
        height: 300,
        alignItems: "center",
        overflow: "auto",
      }}
    >
      <GoogleMapReact
        // AIzaSyBJsrNdBFU6d8XXw8RuG1WSUemMbAVj-ZQ
        // AIzaSyDokz8yiJyqOX5SbfMZz3AQ1NHknT8VH3g
        // AIzaSyAxLE7ntqZ8mDPvbLC7yvTg_wkwPedaseY
        // AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o
        bootstrapURLKeys={{
          key: "AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o",
        }}
        defaultCenter={props.center}
        defaultZoom={15}
      >
        <AnyReactComponent
          lat={props.center.lat}
          lng={props.center.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
};

export default GMap;
