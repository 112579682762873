import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import LineGuard from "../../components/LineGuard";
import customerService from "../../service/customerService";

interface IParams {
  orderType: string;
  orderItem: string;
}

const OrderRequest: React.FC<RouteComponentProps<IParams>> = (props) => {
  const history = useHistory();

  const [orderType, setOrderType] = useState<string>("");
  const [orderItem, setOrderItem] = useState<string>("");
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [custId, setCustId] = useState<number>(0);

  const [lineUserId, setLineUserId] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const [pictureURL, setPictureURL] = useState<string>("");

  useEffect(() => {
    console.log(props);
    const _orderType: string = String(props.match.params.orderType);
    const _orderItem: string = String(props.match.params.orderItem);
    setOrderType(_orderType);
    setOrderItem(_orderItem);
    console.log("(orderType, orderItem) ", _orderType, _orderItem);
  }, []);

  useEffect(() => {
    const lineUserId = localStorage.getItem("LINE_USER_ID") as string;
    setLineUserId(localStorage.getItem("LINE_USER_ID") as string);
    setDisplayName(localStorage.getItem("DISPLAY_NAME") as string);
    setPictureURL(localStorage.getItem("PICTURE_URL") as string);

    customerService
      .getCustLineId("777")
      .then((res) => {
        console.log("customer", res);
        if (res.data === "") {
          setIsRegister(true);
          setCustId(0);
        } else {
          setIsRegister(false);
          setCustId(res.data.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderType]);

  const registerHandler = () => {
    history.push("/app/user/register");
  };

  const orderHandler = () => {
    history.push(`/app/order/cust/${custId}/form`);
  };

  return (
    <LineGuard>
      <div>
        <span>OrderType: {orderType}</span>
        <br />
        <span>OrderItem: {orderItem}</span>
      </div>
      <div>User LINE ID : {lineUserId} </div>
      <div>Display Name: {displayName} </div>
      <div>Picture URL : {pictureURL} </div>

      <div>
        {isRegister && <Button onClick={registerHandler}>Register</Button>}
        <br />
        {!isRegister && <Button onClick={orderHandler}>Order</Button>}
      </div>
    </LineGuard>
  );
};

export default OrderRequest;
