import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row, Button } from "antd";

const TabShipped: React.FC = () => {
  const [form] = Form.useForm();

  const monthNums = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const dateObj = new Date();
  const month = monthNums[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear() + 543;
  const dateStr = " : " + day + "/" + month + "/" + year;

  return (
    <>
      <Row>
        <Col span={1}></Col>
        <Col span={22}>
          <Form form={form} name="basic">
            <div
              style={{
                // border: "1.5px solid #D3D3D3",
                // borderRadius: "8px",
                // backgroundColor: "#f5f5f5",
                paddingLeft: "10px",
                paddingTop: "8px",
                paddingBottom: "6px",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              วันที่
              {dateStr}
            </div>

            <Card
              headStyle={{
                borderBottom: "1px solid #D3D3D3",
              }}
              title={
                <div style={{ fontSize: "12px" }}>
                  <Row gutter={24}>
                    <Col span={16}>
                      <p>เลขพัสดุ TH210722NS034</p>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <p>เซ็นรับแล้ว</p>
                    </Col>
                  </Row>
                </div>
              }
            >
              <div>
                <p>คุณกริยากร สุวรรณ์</p>
                <p>0814526336</p>
                <p>เชียงใหม่</p>
              </div>
            </Card>

            <Card
              headStyle={{
                borderBottom: "1px solid #D3D3D3",
              }}
              title={
                <div style={{ fontSize: "12px" }}>
                  <Row gutter={24}>
                    <Col span={16}>
                      <p>เลขพัสดุ TH210722NS035</p>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <p>เซ็นรับแล้ว</p>
                    </Col>
                  </Row>
                </div>
              }
            >
              <div>
                <p>คุณวีระญา ทาอินทร์</p>
                <p>0617959999</p>
                <p>เชียงใหม่</p>
              </div>
            </Card>
          </Form>
        </Col>
        <Col span={1}></Col>
      </Row>
    </>
  );
};

export default TabShipped;
