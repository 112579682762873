import React, { useEffect, useState } from "react";
import liff from "@line/liff";

function Home() {
  const [lineUserId, setLineUserId] = useState<string>("");

  const loadLiff = () => {
    liff
      .init({ liffId: `1656263228-ExQyRAbr` })
      .then(() => {
        console.log("this is init 1");
        if (liff.isLoggedIn()) {
          console.log("line is loggedIn");
          liff.getProfile().then((res) => {
            console.log("res", res);
            // setLineToStorage(
            //   res.userId,
            //   res.displayName,
            //   res.pictureUrl as string
            // );
          });
        } else {
          console.log("line is not login in");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    const userLineId = localStorage.getItem("USER_LINE_ID") as string;
    console.log("useEffect::localStorage::(userLineId)", userLineId);
    if (userLineId === null) {
      loadLiff();
    }

    console.log("end effect");
  }, []);

  return <div>Home Page {lineUserId}</div>;
}

export default Home;
