import React, { useState, useEffect } from "react";
import { Col, Form, Input, Row, Button } from "antd";
import { RouteComponentProps } from "react-router-dom";
import orderService from "src/service/orderService";
import { Order } from "src/domain/orderType";
const { TextArea } = Input;

interface IParams {
  orderNo: string;
  custId: string;
}

const ViewOrder: React.FC<RouteComponentProps<IParams>> = (props) => {
  const [form] = Form.useForm();
  const [order, setOrder] = useState<Order>({} as Order);
  useEffect(() => {
    let orderNo: string = String(props.match.params.orderNo);
    console.log("orderNo >>>>:: " + orderNo);
    orderService.getOrderNo(orderNo).then((res: any) => {
      console.log(">>>>:: " + res.data.orderNo);
      setOrder(res.data);
    });
  }, []);

  return (
    <>
      <Row>
        <Col span={1}></Col>
        <Col span={22}>
          <Form form={form} name="basic">
            <br />
            <Row style={{ marginTop: "2em" }}>
              <Col span={24}>
                <div
                  style={{
                    border: "1.5px solid #D3D3D3",
                    borderRadius: "8px",
                    backgroundColor: "#f5f5f5",
                    paddingLeft: "15px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      margin: "5px 0",
                      paddingRight: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Order :
                  </label>
                  <label>{order.orderNo}</label>
                  <br />
                  <label
                    style={{
                      display: "inline-block",
                      margin: "5px 0",
                      paddingRight: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    สินค้า :
                  </label>
                  <label>ดอกไม้</label>
                  <br />
                  <label
                    style={{
                      display: "inline-block",
                      margin: "5px 0",
                      paddingRight: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    สถานะ :
                  </label>
                  <label>BOOK</label>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row style={{ marginTop: "0.1em" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Col>
            </Row>
          </Form>
        </Col>
        <Col span={1}></Col>
      </Row>
    </>
  );
};

export default ViewOrder;
